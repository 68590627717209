export const affiliates = [
  {
    firstName: "London",
    image: "london",
    aspectRatio: 384 / 426,
  },
  {
    firstName: "Hong Kong",
    image: "hongKong",
    aspectRatio: 384 / 426,
  },
  {
    firstName: "Dublin",
    image: "dublin",
    aspectRatio: 384 / 426,
  },
  {
    firstName: "Beyond",
    image: "beyond",
    aspectRatio: 384 / 426,
  },
];
