import React, { useEffect, useRef } from "react";
import { graphql } from "gatsby";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TeamCard } from "../components/team-card";
import { affiliates } from "../data/pages/locations-and-affiliates";
import { Hero } from "../components/hero";
import { assetArrayToObject } from "../lib/graphql-helpers";
import "../styles/pages/locations-and-affiliates.scss";
gsap.registerPlugin(ScrollTrigger);

const LocationsAndAffiliates = ({ data }) => {
  const {
    allFile: { edges: allImages },
  } = data;

  const images = assetArrayToObject({ useGatsbyImage: true, images: allImages });

  const heroData = {
    title: "Our Locations & Affiliates",
    image: `${images["locationsHero"]}`,
  };

  const locationsRef = useRef(null);

  useEffect(() => {
    if (locationsRef.current) {
      const currentImage = document.querySelectorAll(".team-card__image");
      gsap.fromTo(
        currentImage,
        {
          y: -100,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: locationsRef.current,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
  }, []);

  return (
    <div className="locations-and-affiliates">
      <Hero data={heroData}/>
      <section className="locations-and-affiliates__text">
        <div className="locations-and-affiliates__text__inner">
          <div className="locations-and-affiliates__text__header">
            <h3>Small Team.</h3>
            <h3>Global Reach.</h3>
          </div>
          <p>
            Our team primarily focuses on staffing positions along the East and
            West coasts of the United States, but we also have experience
            filling roles throughout the country. Although we have a small team,
            we are intimately involved in each search. To meet the demand for
            in-house attorneys in financial hubs such as London, Dublin, Hong
            Kong, and Singapore, we have established a global network of
            recruiters who share our values, expertise, and approach. Clients
            seeking to fill positions abroad can rely solely on our team to
            manage the recruitment process through our search partners, ensuring
            that only top-notch candidates are presented to your organization.
          </p>
        </div>
      </section>
      <section className="locations-and-affiliates__affiliates">
        <div ref={locationsRef} className="locations-and-affiliates__affiliates__inner">
          {affiliates.map((teamMember) => {
            return (
              <div
                key={teamMember.name}
                className="locations-and-affiliates__member"
              >
                <TeamCard
                  image={images[teamMember.image]}
                  data={teamMember}
                  size="medium"
                  isAffiliate={true}
                />
                <p>{teamMember.description}</p>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "locations-and-affiliates" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default LocationsAndAffiliates;
